"use client";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

function CategoryList({ categoryList }) {
  const [catDeskName, setCatDeskName] = useState({ name: "", description: "" });

  useEffect(() => {
    const fetchCategoryDeskName = async () => {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/api/category-desktop-name`
        );
        const data = await response.json();
        setCatDeskName({
          name: data.data.attributes.name,
          description: data.data.attributes.description,
        });
      } catch (error) {
        console.error("Category desktop name:", error);
      }
    };
    fetchCategoryDeskName();
  }, []);

  return (
    <div>
      <div className="flex flex-col items-center">
        <motion.h2
          initial={{ opacity: 0, y: -20, scale: 0.95 }}
          whileInView={{ opacity: 1, y: 0, scale: 1 }}
          transition={{
            duration: 1,
            delay: 0.8,
            type: "spring",
            stiffness: 100,
          }}
          viewport={{ once: true }}
          className="text-[20px] md:text-[24px] lg:text-[28px] xl:text-[32px] 2xl:text-[36px] font-bold uppercase text-[#31A3DE]"
        >
          {catDeskName.name}
        </motion.h2>
        <motion.span
          initial={{ opacity: 0, y: -20, scale: 0.95 }}
          whileInView={{ opacity: 1, y: 0, scale: 1 }}
          transition={{
            duration: 1,
            delay: 0.9,
            type: "spring",
            stiffness: 100,
          }}
          viewport={{ once: true }}
          className="font-medium md:text-lg lg:text-[20px] text-center text-[#326B95] mt-3 hidden sm:block"
        >
          {catDeskName.description}
        </motion.span>
      </div>
      <motion.div
        initial={{ opacity: 0, y: -20, scale: 0.95 }}
        whileInView={{ opacity: 1, y: 0, scale: 1 }}
        transition={{ duration: 1, delay: 1, type: "spring", stiffness: 100 }}
        viewport={{ once: true }}
        className="grid w-full grid-cols-2 md:grid-cols-3 lg:grid-cols-4 py-6 md:py-8 lg:py-12 xl:py-14 2xl:py-16 mb-12 md:mb-0 gap-5 md:gap-20 lg:gap-10 xl:gap-20 justify-items-center"
      >
        {categoryList.length > 0 ? (
          categoryList.map((category, index) => (
            <div
              key={index}
              className="flex flex-col items-center gap-2 md:gap-0 w-full p-2 rounded-[30px] md:border-none border border-[#E6E6E6] border-b-8 hover:border-[#40A4D8] transition duration-500 ease-in-out"
            >
              <Link
                href={"/contents-category/" + category?.attributes?.name}
                className="shadow-custom border-[#F3F3F3] border w-[82px] h-[82px] md:w-[135px] md:h-[135px] rounded-full flex items-center justify-center md:hover:border-[#40A4D8] transition duration-500 ease-in-out"
              >
                {category?.attributes?.icon?.data?.[0]?.attributes?.url ? (
                  <Image
                    id="icon"
                    src={
                      process.env.NEXT_PUBLIC_BACKEND_BASE_URL +
                      category.attributes.icon.data[0].attributes.url
                    }
                    className="w-[48px] h-[48px] md:w-[64px] md:h-[64px]"
                    alt="icon"
                    width={5000}
                    height={5000}
                  />
                ) : (
                  <div
                    className="rounded-full shadow-custom bg-gray-200 flex items-center justify-center"
                    style={{ width: 160, height: 160 }}
                  >
                    <span className="text-[20px] text-[#605E5E]">No Image</span>
                  </div>
                )}
              </Link>
              <Link
                href={"/contents-category/" + category?.attributes?.name}
                className="w-fit text-sm md:text-lg lg:text-xl xl:text-2xl font-semibold whitespace-nowrap md:mt-7 text-[#333333]"
              >
                {category?.attributes?.name}
              </Link>
              <span className="hidden">
                {category?.attributes?.description}
              </span>
            </div>
          ))
        ) : (
          <span className="text-center text-[20px] text-[#4c4d4f] col-span-4">
            Тохирох үр дүн олдсонгүй
          </span>
        )}
      </motion.div>
    </div>
  );
}

export default CategoryList;
