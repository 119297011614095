"use client";
import React from "react";
import Image from "next/image";
import Link from "next/link";
import { ArrowRight } from "lucide-react";
export default function Contents({ contentsList }) {
  return (
    <div className="flex w-full md:justify-center">
      {contentsList.map((contents, index) => (
        <div
          className="md:w-[600px] lg:w-full h-[170px] md:h-[236px] xl:h-[245px] flex items-center gap-3 md:gap-6 p-3 md:p-6 xl:p-7 shadow-custom border-[#F3F3F3] border rounded-[32px]"
          key={index}
        >
          <div className="w-2/3 h-full md:h-[190px] flex justify-center rounded-[24px]">
            {contents.attributes.Image?.data?.[0]?.attributes?.url ? (
              <Image
                src={
                  process.env.NEXT_PUBLIC_BACKEND_BASE_URL +
                  contents.attributes.Image.data[0].attributes.url
                }
                alt={
                  contents.attributes.Image.data[0].attributes
                    .alternativeText || "image"
                }
                className="w-full h-full object-cover rounded-3xl border border-[#f2f2f2]"
                width={5000}
                height={5000}
              />
            ) : (
              <span>No Image Available</span>
            )}
          </div>
          <div className="flex flex-col w-full h-full rounded-[24px]">
            <div className="w-full h-full">
              <h2 className="text-xl lg:text-[24px] xl:text-[28px] 2xl:text-[32px] text-[#333333] font-semibold leading-7">
                {contents.attributes.Title}
              </h2>
              {/* <div className="bg-[#DDF7F8] text-[#0DC0E0] text-xs p-1 rounded-lg w-fit">
                {contents.attributes.tags?.data?.map((tag, tagIndex) => (
                  <span key={tagIndex}>{tag.attributes.name}</span>
                ))}
              </div> */}
              <span className="rubik mt-[6px] text-[#333333] font-light text-xs md:text-sm lg:text-base line-clamp-[5] md:line-clamp-6 lg:line-clamp-5">
                {contents.attributes.Description}
              </span>
            </div>
            <div className="flex items-end justify-end">
              <Link
                className="w-fit"
                href={"/contents/" + contents.attributes.slug}
                key={index}
              >
                <div className="flex items-end justify-end">
                  <span className="text-[#40A4D8] text-[13px] md:text-[15px] lg:text-base font-semibold flex items-center">
                    Дэлгэрэнгүй
                    <ArrowRight size={16} className="text-[#40A4D8]" />
                  </span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
