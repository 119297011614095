"use client";
import { useEffect, useState, useRef } from "react";
import { Search, EyeOff } from "lucide-react";
import { useRouter } from "next/navigation";
import { motion } from "framer-motion";
import Link from "next/link";
import Contents from "./Contents";
import GlobalApi from "@/utils/GlobalApi";

export default function Home() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  //Search end
  const [firstTagContents, setFirstTagContents] = useState([]);
  const [secondTagContents, setSecondTagContents] = useState([]);
  //Tag end
  const [showAlphabet, setShowAlphabet] = useState(false);
  const alphabetMenuRef = useRef(null);

  //Alpha and suggest end
  const router = useRouter();

  useEffect(() => {
    if (searchTerm.trim()) {
      GlobalApi.getContentsBySearch(searchTerm).then(setSearchResults);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  const handleAlphabetToggle = () => {
    setShowAlphabet(!showAlphabet);
    if (!showAlphabet) {
      setSearchTerm("");
    }
  };

  //Tag shuuj harah heseg
  useEffect(() => {
    // Эхний тагийн хамгийн сүүлд орсон контентыг авах
    GlobalApi.getContentsByTag("Юу, яагаад, юунд?").then((contents) =>
      setFirstTagContents(contents.slice(0, 1))
    );

    // Хоёрдох тагийн хамгийн сүүлд орсон контентыг авах
    GlobalApi.getContentsByTag("Энэ ямар утгатай вэ?").then((contents) =>
      setSecondTagContents(contents.slice(0, 1))
    );
  }, []);
  //Tag shuuj harah heseg

  //Desktop alphabet
  const alphabet = "АБВГДЕЁЖЗИКЛМНӨОПРСТУҮФХЦЧШЭЮЯ".split("");
  const alphabet2 = "АБВГДЕЁЖЗИКЛМНӨОПРСТУҮФХЦЧШЭЮЯ".split("");
  const slice = alphabet2.slice(0, 8);
  const columns = [];
  for (let i = 0; i < slice.length; i += 4) {
    columns.push(slice.slice(i, i + 4));
  }
  //Tablet alphabet
  const alphabetT = "АБВГДЕЁЖЗИ".split("");
  const alphabetT1 = "КЛМНӨОПРСТ".split("");
  const alphabetT2 = "УҮФХЦЧШЭЮЯ".split("");

  // Laptop alphabet
  const alphabetL = "АБВГДЕЁЖЗИКЛМНӨ".split("");
  const alphabetL2 = "ОПРСТУҮФХЦЧШЭЮЯ".split("");

  // Mobile alphabet
  const alphabetM = "АБВГДЕЁ".split("");
  const alphabet1M = "ЖЗИКЛМН".split("");
  const alphabet2M = "ӨОПРСТУ".split("");
  const alphabet3M = "ҮФХЦЧШЭ".split("");
  const alphabet4M = "ЮЯ".split("");

  const handleAlphabetClick = (letter) => {
    router.push(`/alphabet-filtered?letter=${letter}`);
    setShowAlphabet(false);
  };

  //Desktop name fetching start
  const [deskHeroName, setDeskHeroName] = useState({ name: "" });
  useEffect(() => {
    const fetchDeskHeroName = async () => {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/api/desk-hero-name`
        );
        const data = await response.json();
        setDeskHeroName({
          name: data.data.attributes.name,
        });
      } catch (error) {
        console.error("Алдаа:", error);
      }
    };
    fetchDeskHeroName();
  }, []);
  //Desktop name fetching end

  return (
    <>
      <main className="mx-auto">
        <div className="flex flex-col items-center">
          <div className="text-center">
            <motion.h1
              initial={{ opacity: 0, y: 20, scale: 0.95 }}
              whileInView={{ opacity: 1, y: 0, scale: 1 }}
              transition={{
                duration: 1,
                delay: 0.2,
                type: "spring",
                stiffness: 100,
              }}
              viewport={{ once: true }}
              className="text-[24px] md:text-[28px] lg:text-[32px] xl:text-[36px] px-4 md:px-0 pt-8 md:pt-12 lg:pt-14 xl:pt-16 font-bold uppercase text-wrap text-[#31A3DE]"
            >
              {deskHeroName.name}
            </motion.h1>
          </div>
          {/* Хайлт хэсэг */}
          <motion.div
            initial={{ opacity: 0, y: 20, scale: 0.95 }}
            whileInView={{ opacity: 1, y: 0, scale: 1 }}
            transition={{
              duration: 1,
              delay: 0.4,
              type: "spring",
              stiffness: 100,
            }}
            viewport={{ once: true }}
            className="relative mt-[44px] md:mb-[48px] lg:mb-[56px] xl:mb-[64px] rounded-3xl hidden md:block"
          >
            <div className="relative">
              <input
                type="text"
                className="md:w-[500px] lg:w-[590px] xl:w-[709px] h-[56px] focus:ring-0 focus:outline-none bg-[#F5F5F5] placeholder:text-[#6E6E6E] pl-6 p-4 text-[20px] rounded-[49px]"
                placeholder="Хайх"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="absolute right-3 top-2 bg-[#40A4D8] rounded-3xl px-[10px] py-2 cursor-pointer">
                <Search
                  size={24}
                  strokeWidth={1.5}
                  className="font-medium z-50 flex w-full justify-center items-center text-white"
                />
              </div>
            </div>

            {/* Results section */}
            {(searchTerm || searchResults.length > 0) && (
              <section
                className={`absolute mt-2 md:w-[500px] lg:w-[590px] xl:w-[709px] z-50 transition-opacity duration-300 ${
                  searchResults.length > 0 ? "opacity-100" : "opacity-0"
                }`}
              >
                <div className="grid grid-cols-1 gap-3 justify-items-center bg-[#FCFCFC] rounded-3xl py-5 px-5 shadow-custom4">
                  {searchResults.length > 0 ? (
                    searchResults.map((result) => (
                      <Link
                        href={"/contents/" + result.attributes.slug}
                        key={result.id}
                        className="p-2 w-full flex items-center rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#F5F5F5]"
                      >
                        <h3 className="text-center text-lg flex items-center">
                          {result.attributes.Title}
                        </h3>
                      </Link>
                    ))
                  ) : searchTerm ? (
                    <span className="whitespace-nowrap w-full">
                      Илэрц олдсонгүй "{searchTerm}".
                    </span>
                  ) : null}
                </div>
              </section>
            )}
          </motion.div>
          {/* Цагаан толгой хэсгээр хайж буй хэсэг */}
          <motion.section
            initial={{ opacity: 0, y: 10, scale: 0.95 }}
            whileInView={{ opacity: 1, y: 0, scale: 1 }}
            transition={{
              duration: 1,
              delay: 0.4,
              type: "spring",
              stiffness: 100,
            }}
            viewport={{ once: true }}
            className="hidden md:block w-full"
          >
            {/* Desktop */}
            <section className="hidden 2xl:block">
              <div className="text-[#40A4D8] w-full h-[148px] flex justify-center items-center">
                <div className="w-full flex items-center gap-[3px] text-[36px]">
                  {alphabet.map((letter, index) => (
                    <div
                      key={index}
                      className="cursor-pointer w-full h-[50px] flex justify-center items-center text-[#6B84A9] border-2 border-transparent rounded-xl hover:border-[#40A4D8] hover:text-[#40A4D8] transition duration-500 ease-in-out"
                      onClick={() => handleAlphabetClick(letter)}
                    >
                      <div className="w-full flex justify-center font-bold">
                        {letter}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            {/* Laptop*/}
            <section className="hidden lg:block 2xl:hidden">
              <div className="text-[#40A4D8] w-full h-[148px] flex flex-col items-center justify-center">
                <div className="w-full flex justify-center gap-4 lg:text-[28px] xl:text-[32px] pb-5">
                  {alphabetL.map((letter, index) => (
                    <div
                      key={index}
                      className="cursor-pointer min-w-[45.5px] h-[45.5px] font-bold flex justify-center items-center text-[#6B84A9] border-2 border-transparent rounded-xl hover:border-[#40A4D8] hover:text-[#40A4D8] transition duration-500 ease-in-out"
                      onClick={() => handleAlphabetClick(letter)}
                    >
                      {letter}
                    </div>
                  ))}
                </div>
                <div className="w-full flex justify-center gap-4 lg:text-[28px] xl:text-[32px]">
                  {alphabetL2.map((letter, index) => (
                    <div
                      key={index}
                      className="cursor-pointer min-w-[45.5px] h-[45.5px] font-bold flex justify-center items-center text-[#6B84A9] border-2 border-transparent rounded-xl hover:border-[#40A4D8] hover:text-[#40A4D8] transition duration-500 ease-in-out"
                      onClick={() => handleAlphabetClick(letter)}
                    >
                      {letter}
                    </div>
                  ))}
                </div>
              </div>
            </section>
            {/* Tablet */}
            <div className="block lg:hidden">
              <div className="w-full h-[148px] gap-2">
                <div className="flex">
                  {alphabetT.map((letter, index) => (
                    <div
                      key={index}
                      className="cursor-pointer w-full h-[50px] flex justify-center items-center text-[#6B84A9] border-2 border-transparent rounded-xl hover:border-[#40A4D8] hover:text-[#40A4D8] transition duration-500 ease-in-out"
                      onClick={() => handleAlphabetClick(letter)}
                    >
                      <div className="w-full flex justify-center font-bold text-[24px]">
                        {letter}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex">
                  {alphabetT1.map((letter, index) => (
                    <div
                      key={index}
                      className="cursor-pointer w-full h-[50px] flex justify-center items-center text-[#6B84A9] border-2 border-transparent rounded-xl hover:border-[#40A4D8] hover:text-[#40A4D8] transition duration-500 ease-in-out"
                      onClick={() => handleAlphabetClick(letter)}
                    >
                      <div className="w-full flex justify-center font-bold text-[24px]">
                        {letter}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex">
                  {alphabetT2.map((letter, index) => (
                    <div
                      key={index}
                      className="cursor-pointer w-full h-[50px] flex justify-center items-center text-[#6B84A9] border-2 border-transparent rounded-xl hover:border-[#40A4D8] hover:text-[#40A4D8] transition duration-500 ease-in-out"
                      onClick={() => handleAlphabetClick(letter)}
                    >
                      <div className="w-full flex justify-center font-bold text-[24px]">
                        {letter}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </motion.section>
          {/* Хайлт хэсэг ба цагаан толгой хайлт Mobile*/}
          <motion.div
            initial={{ opacity: 0, y: 20, scale: 0.95 }}
            whileInView={{ opacity: 1, y: 0, scale: 1 }}
            transition={{
              duration: 1,
              delay: 0.4,
              type: "spring",
              stiffness: 100,
            }}
            viewport={{ once: true }}
            className="block md:hidden w-full flex items-center justify-center gap-2 mt-6"
          >
            <div className="relative flex flex-col items-center justify-center border border-[#40A4D8] rounded-[32px]">
              <input
                type="text"
                className="w-[258px] focus:ring-0 focus:outline-none p-2 pl-4 placeholder:text-[#6E6E6E] text-base rounded-full"
                placeholder="Хайх"
                value={searchTerm}
                onFocus={() => setShowAlphabet(false)}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="absolute right-[5.5px] top-[50%] transform -translate-y-[50%] bg-[#40A4D8] rounded-3xl p-[6.5px] cursor-pointer">
                <Search
                  size={20}
                  strokeWidth={2}
                  className="font-medium z-50 flex justify-center items-center text-white"
                />
              </div>
              {/* Results section */}
              {(searchTerm || searchResults.length > 0) && (
                <section
                  className={`absolute mt-[8px] w-[258px] top-[100%] left-0 z-10 transition-opacity duration-300 ${
                    searchResults.length > 0 ? "opacity-100" : "opacity-0"
                  }`}
                >
                  <div className="grid grid-cols-1 gap-3 justify-items-center bg-[#FCFCFC] rounded-3xl py-5 px-5 shadow-custom4">
                    {searchResults.length > 0
                      ? searchResults.map((result) => (
                          <Link
                            href={"/contents/" + result.attributes.slug}
                            key={result.id}
                            className="p-2 w-full flex items-center rounded-lg transition-colors duration-300 ease-in-out hover:bg-[#F5F5F5]"
                          >
                            <h3 className="text-center text-base flex items-center">
                              {result.attributes.Title}
                            </h3>
                          </Link>
                        ))
                      : searchTerm && (
                          <span className="whitespace-nowrap w-full text-center">
                            Илэрц олдсонгүй "{searchTerm}".
                          </span>
                        )}
                  </div>
                </section>
              )}
            </div>
            <div className="h-[44px] flex">
              <button
                onClick={handleAlphabetToggle}
                className="bg-[#fff] px-5 py-2 text-[#00477C] border border-[#40A4D8] font-semibold rounded-3xl whitespace-nowrap"
              >
                А-Я
              </button>
            </div>

            {showAlphabet && (
              <section
                ref={alphabetMenuRef}
                className="absolute w-[337px] top-[230px] sm:top-[200px] min-[521px]:top-[200px] flex flex-col gap-4 bg-white py-4 px-4 rounded-3xl z-50 border-b border-[#D9D9D9]"
              >
                <div className="w-full grid grid-cols-7 justify-items-center gap-4">
                  {alphabetM.map((letter, index) => (
                    <div
                      key={index}
                      className="cursor-pointer w-[34px] h-[34px] flex justify-center items-center border border-[#40A4D8] text-[#40A4D8] rounded-full px-3 hover:bg-[#40A4D8] hover:text-white transition-colors duration-300 ease-in-out"
                      onClick={() => handleAlphabetClick(letter)}
                    >
                      {letter}
                    </div>
                  ))}
                </div>
                <div className="w-full grid grid-cols-7 justify-items-center gap-4">
                  {alphabet1M.map((letter, index) => (
                    <div
                      key={index}
                      className="cursor-pointer w-[34px] h-[34px] flex justify-center items-center border border-[#40A4D8] text-[#40A4D8] rounded-full px-3 hover:bg-[#40A4D8] hover:text-white transition-colors duration-300 ease-in-out"
                      onClick={() => handleAlphabetClick(letter)}
                    >
                      {letter}
                    </div>
                  ))}
                </div>
                <div className="w-full grid grid-cols-7 justify-items-center gap-4">
                  {alphabet2M.map((letter, index) => (
                    <div
                      key={index}
                      className="cursor-pointer w-[34px] h-[34px] flex justify-center items-center border border-[#40A4D8] text-[#40A4D8] rounded-full px-3 hover:bg-[#40A4D8] hover:text-white transition-colors duration-300 ease-in-out"
                      onClick={() => handleAlphabetClick(letter)}
                    >
                      {letter}
                    </div>
                  ))}
                </div>
                <div className="w-full grid grid-cols-7 justify-items-center gap-4">
                  {alphabet3M.map((letter, index) => (
                    <div
                      key={index}
                      className="cursor-pointer w-[34px] h-[34px] flex justify-center items-center border border-[#40A4D8] text-[#40A4D8] rounded-full px-3"
                      onClick={() => handleAlphabetClick(letter)}
                    >
                      {letter}
                    </div>
                  ))}
                </div>
                <div className="w-full grid grid-cols-7 justify-items-center gap-4">
                  {alphabet4M.map((letter, index) => (
                    <div
                      key={index}
                      className="cursor-pointer w-[34px] h-[34px] flex justify-center items-center border border-[#40A4D8] text-[#40A4D8] rounded-full"
                      onClick={() => handleAlphabetClick(letter)}
                    >
                      {letter}
                    </div>
                  ))}
                </div>
              </section>
            )}
          </motion.div>
        </div>
        {/* Таг-аар шүүх харж байгаа хэсэг */}
        <section className="flex flex-col lg:flex-row w-full gap-12 md:gap-[52px] lg:gap-6 py-12 md:py-14 lg:py-20 xl:pb-24 xl:pt-[60px] 2xl:pb-[100px] 2xl:pt-16">
          <div className="flex w-full flex-col">
            <motion.h2
              initial={{ opacity: 0, y: -10, scale: 0.95 }}
              whileInView={{ opacity: 1, y: 0, scale: 1 }}
              transition={{
                duration: 1,
                delay: 0.6,
                type: "spring",
                stiffness: 100,
              }}
              viewport={{ once: true }}
              className="text-[20px] md:text-[24px] lg:text-[28px] xl:text-[32px] mb-4 md:mb-5 lg:mb-6 text-center font-bold uppercase text-[#12C96F]"
            >
              “Юу, яагаад, юунд?”
            </motion.h2>
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{
                duration: 1,
                delay: 0.75,
                type: "spring",
                stiffness: 100,
              }}
              viewport={{ once: true }}
              className="flex items-center w-full"
            >
              <Contents contentsList={firstTagContents} />
            </motion.div>
          </div>
          <div className="flex w-full flex-col">
            <motion.h2
              initial={{ opacity: 0, y: -10, scale: 0.95 }}
              whileInView={{ opacity: 1, y: 0, scale: 1 }}
              transition={{
                duration: 1,
                delay: 0.6,
                type: "spring",
                stiffness: 100,
              }}
              viewport={{ once: true }}
              className="text-[20px] md:text-[24px] lg:text-[28px] xl:text-[32px] mb-4 md:mb-5 lg:mb-6 text-center font-bold uppercase text-[#12C96F]"
            >
              “Энэ ямар утгатай вэ?”
            </motion.h2>
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{
                duration: 1,
                delay: 0.75,
                type: "spring",
                stiffness: 100,
              }}
              viewport={{ once: true }}
              className="flex items-center w-full"
            >
              <Contents contentsList={secondTagContents} />
            </motion.div>
          </div>
        </section>
      </main>
    </>
  );
}
